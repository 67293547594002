import React, { useContext } from 'react'
import React360 from '../React360'
import ModalDefault from '../Modal'
import useMedia from '../../hooks/useMedia'
import { GlobalContext } from '../../Context/global'

import {
  AllCourt,
  Baseline,
  BestOfBoth,
  Ico360,
  CourtFFNovak360,
  GelResolution360,
  SolutionSpeedFf2360,
} from '../../images/cps'

import * as S from './styles'

function renderReact360(modelo) {
  switch (modelo) {
    case 'court':
      return <React360 dir="court-360" alt="COURT FF ™ Novak" />
    case 'resolution':
      return <React360 dir="gel-resolution-360" alt="GEL-RESOLUTION ™ 8" />
    case 'solution':
      return <React360 dir="solution-speed-360" alt="SOLUTION SPEED™ FF 2" />
    default:
      return null
  }
}

const CPS360 = () => {
  const { handleModalOpen, active360 } = useContext(GlobalContext)
  const { isMobile } = useMedia()

  return (
    <>
      {!isMobile ? (
        <ModalDefault>
          {renderReact360(active360)}
          <figure className="icone-arrastar">
            <svg
              enableBackground="new 0 0 60 30"
              id="Layer_1"
              version="1.1"
              viewBox="0 0 60 30"
            >
              <g>
                <g>
                  <polygon points="56,10 53.44,10 56.641,14 50,14 50,16 56.639,16 53.44,20 56,20 60,14.999" />
                  <polygon points="4,20 6.562,20 3.36,16 10,16 10,14 3.36,14 6.562,10 4,10 0,15.001" />
                  <g>
                    <path d="M38.098,25.153C37.924,13.162,35.983,8,30.001,8c-5.982,0-7.924,5.162-8.098,17.153      C18.919,22.77,17.001,19.107,17.001,15c0-7.169,5.83-13,13-13c7.167,0,13,5.831,13,13C43,19.107,41.081,22.77,38.098,25.153       M29.981,19.989c-6.018,0-5.127-7.31-5.127-7.31s1.027-2.316,5.127-2.316c4.101,0,5.169,2.316,5.169,2.316      S35.997,19.989,29.981,19.989 M30.001,0c-8.271,0-15,6.729-15,15c0,8.271,6.729,15,15,15c8.271,0,15-6.729,15-15      C45,6.729,38.272,0,30.001,0" />
                  </g>
                </g>
              </g>
            </svg>
            <figcaption>clique e arraste para visualizar 360°</figcaption>
          </figure>
        </ModalDefault>
      ) : null}

      <S.Wrapper360>
        <S.Item360>
          <S.Titulo360>
            controle <br /> e <strong>velocidade</strong>
          </S.Titulo360>
          <S.ThumbAreaJogo>
            <img src={BestOfBoth} alt="controle e velocidade" />
          </S.ThumbAreaJogo>
          <S.Thumb360>
            {!isMobile ? (
              <img
                src={CourtFFNovak360}
                alt="COURT FF ™ Novak"
                onClick={() => handleModalOpen('court')}
              />
            ) : (
              <React360 dir="court-360" alt="COURT FF ™ Novak" />
            )}
          </S.Thumb360>
          <S.Ico360>
            <img
              src={Ico360}
              alt="360"
              onClick={() => handleModalOpen('court')}
            />
          </S.Ico360>
        </S.Item360>
        <S.Item360>
          <S.Titulo360>
            controle <br /> <strong>de bola</strong>
          </S.Titulo360>
          <S.ThumbAreaJogo>
            <img src={Baseline} alt="controle de bola" />
          </S.ThumbAreaJogo>
          <S.Thumb360>
            {!isMobile ? (
              <img
                src={GelResolution360}
                alt="GEL-RESOLUTION ™ 8"
                onClick={() => handleModalOpen('resolution')}
              />
            ) : (
              <React360 dir="gel-resolution-360" alt="GEL-RESOLUTION ™ 8" />
            )}
          </S.Thumb360>
          <S.Ico360>
            <img
              src={Ico360}
              alt="360"
              onClick={() => handleModalOpen('resolution')}
            />
          </S.Ico360>
        </S.Item360>
        <S.Item360>
          <S.Titulo360>
            velocidade <br /> <strong>na quadra</strong>
          </S.Titulo360>
          <S.ThumbAreaJogo>
            <img src={AllCourt} alt="velocidade na quadra" />
          </S.ThumbAreaJogo>
          <S.Thumb360>
            {!isMobile ? (
              <img
                src={SolutionSpeedFf2360}
                alt="SOLUTION SPEED™ FF 2"
                onClick={() => handleModalOpen('solution')}
              />
            ) : (
              <React360 dir="solution-speed-360" alt="SOLUTION SPEED™ FF 2" />
            )}
          </S.Thumb360>
          <S.Ico360>
            <img
              src={Ico360}
              alt="360"
              onClick={() => handleModalOpen('solution')}
            />
          </S.Ico360>
        </S.Item360>
      </S.Wrapper360>
    </>
  )
}

export default CPS360
