import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Trans, useI18next } from 'gatsby-plugin-react-i18next'
import { URL_PRIVACIDADE } from '../../constants'

import * as S from './styles'

const Checkbox = ({ name, id, value, checked, onChange, labelTxt }) => {
  const { language } = useI18next()
  return (
    <>
      <S.Checkbox
        type="checkbox"
        name={name}
        id={id}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      {id === 'politica' ? (
        <label htmlFor={id}>
          <a
            href={URL_PRIVACIDADE[language]}
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <Trans>Li e concordo com a Política de Privacidade</Trans>
          </a>
        </label>
      ) : (
        <label htmlFor={id}>{labelTxt}</label>
      )}
    </>
  )
}

Checkbox.defaultProps = {
  labelTxt: '',
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  labelTxt: PropTypes.string,
}

export default Checkbox

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
