import styled from 'styled-components'

export const Wrapper360 = styled.section`
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 100%;
  padding: 50px 0;
  width: 1370px;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 15px;
  }
`

export const Item360 = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 430px;

  @media (max-width: 767px) {
    padding-bottom: 15px;
    width: 90%;
  }

  &:first-child {
    border-right: 3px solid #ebeaf1;

    @media (max-width: 767px) {
      border-bottom: 3px solid #ebeaf1;
      border-right: none;
    }
  }

  :last-child {
    border-left: 3px solid #ebeaf1;

    @media (max-width: 767px) {
      border-bottom: 3px solid #ebeaf1;
      border-left: none;
    }
  }
`

export const Titulo360 = styled.h2`
  color: var(--primary-color);
  font-family: var(--fontRegularItalic);
  font-size: 35px;
  font-style: italic;
  font-weight: normal;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
`

export const ThumbAreaJogo = styled.figure`
  margin: 40px auto;

  img {
    display: block;
    margin: 0 auto;
    text-align: center;
    width: 75%;
  }
`

export const Thumb360 = styled.figure`
  cursor: pointer;

  @media (min-width: 767px) {
    margin-bottom: 15px;
  }

  img {
    display: block;
    margin: 0 auto;
    text-align: center;
    transition: all 0.3s ease;
    width: 85%;

    &:hover {
      transform: scale(0.95);
    }
  }
`

export const Ico360 = styled.figure`
  cursor: pointer;
  margin: 0 auto;

  img {
    display: block;
    margin: 0 auto;
    text-align: center;
    width: 80%;
  }
`
