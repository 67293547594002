import React, { useState, useContext } from 'react'
import { graphql } from 'gatsby'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { ToastContainer, toast } from 'react-toastify'
import CircularProgress from '@material-ui/core/CircularProgress'
import Label from '../Form/label'
import Input from '../Form/input'
import InputMask from '../Form/mask'
import Select from '../Form/select'
import Option from '../Form/option'
import Checkbox from '../Form/checkbox'
import Button from '../Buttons/button'
import ErrorMessage from '../ErrorMessage'
import { requestOptions } from '../../services/API'
import {
  maskTelefone,
  maskTelefoneCO,
  maskTelefoneCL,
} from '../../helpers/util'
import { ditoIdentify } from '../../helpers/dito'
import { GlobalContext } from '../../Context/global'

import * as S from './styles'

const MASK = {
  br: maskTelefone,
  co: maskTelefoneCO,
  cl: maskTelefoneCL,
}

const FormNewsletter = ({ isLP = false, origem = '' }) => {
  const [nome, setNome] = useState('')
  const [genero, setGenero] = useState('')
  const [email, setEmail] = useState('')
  const [telefone, setTelefone] = useState('')
  const [loading, setLoading] = useState(false)
  const [erroCampos, setErroCampos] = useState({})
  const [aceiteTermosUso, setAceiteTermosUso] = useState(false)

  const { language } = useContext(GlobalContext)

  function handleResetForm() {
    setNome('')
    setGenero('')
    setEmail('')
    setAceiteTermosUso(false)
    setTelefone('')
  }

  async function handleSubmit(e) {
    e.preventDefault()

    const dados = {
      nome,
      email,
      genero,
      aceiteTermosUso,
      telefone,
      origem,
    }

    try {
      setLoading(true)
      const response = await fetch(
        `${process.env.GATSBY_API_URL}${language}/newsletter`,
        requestOptions(dados)
      )
      const data = await response.json()
      if (response.ok) {
        console.log('response', response)
        setErroCampos({})
        toast.success(data.message)
        ditoIdentify(dados)
        handleResetForm()
      } else {
        toast.error(data.errors ? undefined : data.message)
        setErroCampos(data.errors || {})
      }
    } catch (err) {
      toast.error(err.message)
    } finally {
      setLoading(false)
    }
  }

  const { t } = useTranslation()

  return (
    <>
      <ToastContainer position="bottom-right" autoClose={5000} />
      <form onSubmit={handleSubmit}>
        <S.ItemForm>
          <Input
            type="hidden"
            name="origem"
            value={origem}
            id="origem"
            placeholder="origem"
          />
          <Label texto="nome">
            <Trans>Nome</Trans>
          </Label>
          <Input
            type="text"
            id="nome"
            value={nome}
            placeholder={t('name_placeholder')}
            onChange={({ target }) => setNome(target.value)}
            className={erroCampos.nome && 'erro'}
            isLP={isLP}
          />
          {erroCampos.nome && <ErrorMessage mensagem={erroCampos.nome} />}
        </S.ItemForm>
        <S.ItemForm>
          <Label texto="genero">
            <Trans>Gênero</Trans>
          </Label>
          <Select
            id="genero"
            value={genero}
            onChange={({ target }) => setGenero(target.value)}
            className={erroCampos.genero && 'erro'}
            isLP={isLP}
          >
            <Option value="">{t('genre_placeholder')}</Option>
            <Option value={'feminino'}>{t('feminino')}</Option>
            <Option value={'masculino'}>{t('masculino')}</Option>
            <Option value={'nao-binario'}>{t('nao-binario')}</Option>
            <Option value={'outro'}>{t('outro')}</Option>
            <Option value={'nao-responder'}>{t('nao-responder')}</Option>
          </Select>
          {erroCampos.genero && <ErrorMessage mensagem={erroCampos.genero} />}
        </S.ItemForm>
        <S.ItemForm>
          <Label texto="email">
            <Trans>e-mail</Trans>
          </Label>
          <Input
            type="email"
            id="email"
            value={email}
            placeholder={t('email_placeholder')}
            onChange={({ target }) => setEmail(target.value)}
            className={erroCampos.email && 'erro'}
            isLP={isLP}
          />
          {erroCampos.email && <ErrorMessage mensagem={erroCampos.email} />}
        </S.ItemForm>
        <S.ItemForm>
          <Label texto="telefone">
            <Trans>Telefone</Trans>
          </Label>
          <InputMask
            type="tel"
            id="telefone"
            value={telefone}
            placeholder={t('phone_placeholder')}
            onChange={({ target }) => setTelefone(target.value)}
            className={cx(
              'mask-input',
              { isLP: isLP },
              { erro: erroCampos.telefone }
            )}
            mask={MASK[language]}
          />
          {erroCampos.telefone && (
            <ErrorMessage mensagem={erroCampos.telefone} />
          )}
        </S.ItemForm>
        <S.ItemForm>
          <Checkbox
            id="politica"
            name="politica"
            value={aceiteTermosUso}
            checked={aceiteTermosUso === true}
            onChange={() => setAceiteTermosUso(!aceiteTermosUso)}
          />
          {erroCampos.aceiteTermosUso && (
            <ErrorMessage mensagem={erroCampos.aceiteTermosUso} />
          )}
        </S.ItemForm>
        <S.ItemForm>
          <Button type="submit" disabled={!!(loading || !aceiteTermosUso)}>
            {loading ? (
              <>
                <CircularProgress size={25} className="circleLoading" />{' '}
                Enviando
              </>
            ) : (
              'Enviar'
            )}
          </Button>
        </S.ItemForm>
      </form>
    </>
  )
}

FormNewsletter.propTypes = {
  isLP: PropTypes.bool,
  origem: PropTypes.string,
}

export default FormNewsletter

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
