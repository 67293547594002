import styled from 'styled-components'

import { bannerTopoLg } from '../../images/cps'

export const BannerTopo = styled.section`
  background-image: url(${bannerTopoLg});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  height: 615px;
  width: 100%;

  @media (max-width: 767px) {
    height: 395px;
    padding: 0 15px;
  }

  @media (min-width: 1600px) {
    height: 779px;
  }
`

export const WrapperBanner = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0 auto;
  max-width: 100%;
  width: 1000px;
`

export const BannerTitulo = styled.h1`
  color: var(--primary-color);
  font-family: var(--fontRegularItalic);
  font-size: 60px;
  font-style: italic;
  font-weight: normal;
  line-height: 70px;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 40px;
  }
`

export const Modelos = styled.section`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  &::before {
    content: '';
    background: rgb(207, 232, 229);
    background: linear-gradient(
      90deg,
      rgba(207, 232, 229, 1) 0%,
      rgba(163, 191, 237, 1) 100%
    );
    width: 50%;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: -1;

    @media (max-width: 767px) {
      display: none;
    }
  }

  &::after {
    background: rgb(223, 224, 241);
    background: linear-gradient(
      260deg,
      rgba(223, 224, 241, 1) 0%,
      rgba(243, 242, 243, 1) 100%
    );
    content: '';
    height: 100%;
    right: 0;
    position: absolute;
    width: 50%;
    top: 0;
    z-index: -1;

    @media (max-width: 767px) {
      display: none;
    }
  }
`

export const ItemModelo = styled.div`
  max-width: 100%;
  position: relative;
  width: 430px;

  &.modelo1 {
    @media (max-width: 767px) {
      background: rgb(207, 232, 229);
      background: linear-gradient(
        90deg,
        rgba(207, 232, 229, 1) 0%,
        rgba(163, 191, 237, 1) 100%
      );
    }
  }

  &.modelo2 {
    background: rgb(243, 242, 243);
    background: linear-gradient(
      90deg,
      rgba(243, 242, 243, 1) 0%,
      rgba(250, 248, 221, 1) 80%
    );
  }

  &.modelo3 {
    background: rgb(223, 224, 241);
    background: linear-gradient(
      260deg,
      rgba(223, 224, 241, 1) 0%,
      rgba(243, 242, 243, 1) 100%
    );
  }
`

export const WrapperItemModelo = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  position: relative;
  width: 100%;

  @media (max-width: 767px) {
    margin: 15px 0;
  }

  &.detail1 {
    &::before {
      @media (max-width: 767px) {
        display: none;
      }

      background-color: rgba(255, 255, 255, 0.8);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 30px;
      z-index: 1;
    }
  }

  &.detail2 {
    &::after {
      background-color: rgba(255, 255, 255, 0.8);
      content: '';
      height: 100%;
      left: 50%;
      position: absolute;
      top: 0;
      width: 30px;
      transform: translateX(-50%);
      z-index: 1;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  &.detail3 {
    &::after {
      background-color: rgba(255, 255, 255, 0.8);
      content: '';
      height: 100%;
      right: 0;
      position: absolute;
      top: 0;
      width: 30px;
      z-index: 1;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }
`

export const TituloModelo = styled.h2`
  color: var(--primary-color);
  font-size: 34px;
  font-family: var(--fontBoldItalic);
  margin-bottom: 15px;
`

export const TextoModelo = styled.p`
  color: var(--primary-color);
  font-size: 20px;
  font-style: italic;
  height: 150px;
  line-height: 24px;
  max-width: 100%;
  padding: 0 15px;
  text-align: center;
  width: 360px;
`

export const DetalhesModelo = styled.ul`
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 20px;
  max-width: 100%;
  padding: 20px 40px;
  width: 330px;

  li {
    color: var(--primary-color);
    font-size: 19px;
    font-style: italic;
    list-style-type: none;
    line-height: 20px;
    padding-left: 20px;
    padding-top: 4px;
    position: relative;
    margin-bottom: 15px;

    &::before {
      content: '';
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
      display: block;
      position: absolute;
      top: 5px;
      left: 0px;
      background-color: #485cc7;
      width: 12px;
      height: 12px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const ThumbModelo = styled.figure`
  padding: 30px 0;
  position: relative;
  width: 100%;
  z-index: 2;

  @media (max-width: 767px) {
    padding: 0;
  }

  img {
    display: block;
    margin: 0 auto;
    text-align: center;
    width: 70%;

    @media (max-width: 767px) {
      width: 60%;
    }
  }
`

export const Feature = styled.div`
  align-items: center;
  display: flex;
  gap: 15px;
  justify-content: space-between;
  margin: 10px auto;

  @media (max-width: 767px) {
    flex-flow: column;
  }

  img {
    width: 120px;

    @media (max-width: 767px) {
      width: 90px;
    }
  }

  p {
    color: var(--primary-color);
    font-size: 16px;
    font-style: italic;
    line-height: 16px;
    padding-right: 10px;
    width: 220px;
  }

  @media (max-width: 767px) {
    padding: 0 15px;
  }
`

export const Linha = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  height: 30px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;

  @media (max-width: 767px) {
    display: none;
  }

  &.linha1 {
    top: 50%;
    transform: translateY(-318px);
  }

  &.linha2 {
    top: 50%;
    transform: translateY(343px);
  }
`

export const WrapperInfosPreco = styled.section`
  background-color: #fff;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 100%;
  padding: 50px 0;
  width: 1370px;

  @media (max-width: 767px) {
    display: none;
  }
`

export const InfosItemPreco = styled.div`
  align-items: center;
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 430px;

  @media (max-width: 767px) {
    border: none;
    padding-bottom: 30px;
    width: 100%;
  }

  &.itemPrecoMobile {
    @media (min-width: 767px) {
      display: none;
    }
  }

  &:first-child {
    border-right: 3px solid #ebeaf1;
  }

  &:last-child {
    border-left: 3px solid #ebeaf1;
  }
`

export const TituloInfoPreco = styled.h3`
  font-family: var(--fontBoldItalic);
  font-size: 24px;
  margin-bottom: 10px;
  text-transform: uppercase;
`

export const TextoInfoPreco = styled.p`
  font-size: 18px;
  min-height: 115px;
  line-height: 18px;
  max-width: 100%;
  width: 300px;
`

export const Preco = styled.p`
  color: var(--primary-color);
  font-size: 45px;
  font-style: italic;

  strong {
    font-size: 50px;
  }
`

export const WrapperNewsletter = styled.section`
  padding: 20px 0;
  width: 100%;

  @media (max-width: 991px) {
    padding: 30px 30px 0 30px;
  }
`

export const ContentNewsletter = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  position: relative;
  width: 100%;

  h2 {
    color: var(--primary-color);
    font-family: var(--fontBoldItalic);
    font-size: 28px;
    margin-bottom: 5px;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 26px;
      line-height: 26px;
    }
  }
`

export const FormNewsletter = styled.div`
  margin: 30px auto;
  width: 60%;

  @media (max-width: 991px) {
    width: 100%;
  }
`
