import React, { useEffect } from 'react'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import NavLink from '../../components/Buttons'
import FormNewsletter from '../../components/Newsletter'
import CPS360 from '../../components/CPSTennis/360'

import { ditoTrack } from '../../helpers/dito'

import {
  CourtFFNovak,
  feature1CourtFfNovak,
  feature1GelResolution,
  feature1SolutionSpeedFf2,
  feature2CourtFfNovak,
  feature2GelResolution,
  feature2SolutionSpeedFf2,
  feature3CourtFfNovak,
  feature3GelResolution,
  feature3SolutionSpeedFf2,
  GelResolution,
  SolutionSpeedFf,
} from '../../images/cps'

import * as S from './styles'
import { graphql } from 'gatsby'

const CPSTennisPage = () => {
  useEffect(() => {
    ditoTrack('acessou-cps-tennis')
  }, [])

  return (
    <Layout>
      <SEO title="CPS TENNIS" />

      <S.BannerTopo>
        <S.WrapperBanner>
          <S.BannerTitulo>
            eleve seu nível na <br /> quadra <strong>ao máximo</strong>
          </S.BannerTitulo>
        </S.WrapperBanner>
      </S.BannerTopo>

      <CPS360 />

      <S.Modelos>
        <S.Linha className="linha1" />
        <S.Linha className="linha2" />

        <S.ItemModelo className="modelo1">
          <S.WrapperItemModelo>
            <S.TituloModelo>COURT FF ™ Novak</S.TituloModelo>
            <S.TextoModelo>
              Inspirado nos melhores do ranking mundial, esse modelo traz a
              flexibilidade e o suporte em toda a sua construção para ter
              facilidade de deslocamento em quadra.
            </S.TextoModelo>
            <S.DetalhesModelo>
              <li>Movimentação e velocidade</li>
              <li>Fundo de quadra agressivo</li>
              <li>
                Amortecimento, segurança <br /> e flexibilidade
              </li>
              <li>
                Principais tecnologias: <br /> FLYTEFOAM ™ e GEL ™
              </li>
            </S.DetalhesModelo>
          </S.WrapperItemModelo>

          <S.WrapperItemModelo className="detail1">
            <S.ThumbModelo>
              <img src={CourtFFNovak} alt="COURT FF ™ Novak" />
            </S.ThumbModelo>
          </S.WrapperItemModelo>

          <S.WrapperItemModelo className="detail1">
            <S.Feature>
              <img src={feature1CourtFfNovak} alt="" />
              <p>
                <strong>FLYTEFOAM ™ e GEL ™</strong>
                <br />
                Tecnologias combinadas para fornecer passadas adequadas e
                excelente conforto no amortecimento
              </p>
            </S.Feature>
            <S.Feature>
              <img src={feature2CourtFfNovak} alt="" />
              <p>
                <strong>PGUARD ™ e AHAR ™</strong>
                <br />
                Protetor de dedo e sola que aumentam a durabilidade de seu
                composto de borracha
              </p>
            </S.Feature>
            <S.Feature>
              <img src={feature3CourtFfNovak} alt="" />
              <p>
                <strong>TRUSSTIC SYSTEM ™</strong>
                <br />
                para dar mais leveza, suporte e segurança que seu jogo precisa
              </p>
            </S.Feature>
          </S.WrapperItemModelo>

          <S.InfosItemPreco className="itemPrecoMobile">
            <S.TituloInfoPreco>pra quem é esse calçado:</S.TituloInfoPreco>
            <S.TextoInfoPreco>
              O <strong>COURT FF ™ Novak</strong> é um tênis ideal para
              jogadores que gostam de treinar seu controle de velocidade e
              movimentação. Se você tem um jogo fundo de quadra agressivo
              inspirado no melhor do mundo, esse modelo é para você.
            </S.TextoInfoPreco>
            <S.Preco>
              R$ <strong>1099</strong>,99
            </S.Preco>
            <NavLink
              element="a"
              href="https://www.asics.com.br/calcados?filtro_87=Court-FF-Novak&utm_source=lab&utm_medium=lp&utm_campaign=crm-cps-tennisSolution&paged=1"
              target="_blank"
              rel="nofollower noreferrer noopener"
            >
              comprar agora
            </NavLink>
          </S.InfosItemPreco>
        </S.ItemModelo>

        <S.ItemModelo className="modelo2">
          <S.WrapperItemModelo>
            <S.TituloModelo>GEL-RESOLUTION ™ 8</S.TituloModelo>
            <S.TextoModelo>
              Passadas responsivas e controle em toda a quadra. O GEL-RESOLUTION
              ™ 8 entrega uma resposta rápida e proximidade com o solo para quem
              joga no All-Court e gosta de surpreender o adversário.
            </S.TextoModelo>
            <S.DetalhesModelo>
              <li>Controle na quadra</li>
              <li>
                Para tenistas que jogam <br /> tanto no fundo da quadra <br />{' '}
                quanto na rede
              </li>
              <li>Responsividade e conforto</li>
              <li>
                Principais tecnologias:
                <br /> FLEXION FIT ™ e DYNAWALL ™
              </li>
            </S.DetalhesModelo>
          </S.WrapperItemModelo>

          <S.WrapperItemModelo className="detail2">
            <S.ThumbModelo>
              <img src={GelResolution} alt="GEL-RESOLUTION ™ 8" />
            </S.ThumbModelo>
          </S.WrapperItemModelo>

          <S.WrapperItemModelo>
            <S.Feature>
              <img src={feature1GelResolution} alt="" />
              <p>
                <strong>GEL ™ </strong>
                <br />
                Tecnologia no calcanhar para reduzir o impacto do choque causado
                por movimentos bruscos
              </p>
            </S.Feature>
            <S.Feature>
              <img src={feature2GelResolution} alt="" />
              <p>
                <strong>AHAR ™ e PGUARD ™ </strong>
                <br />
                Composto na sola que funciona em conjunto com o protetor de dedo
                que aumenta a tração na quadra e reduz o nível geral de desgaste
              </p>
            </S.Feature>
            <S.Feature>
              <img src={feature3GelResolution} alt="" />
              <p>
                <strong>FLEXION FIT ™ e DYNAWALL ™ </strong>
                Tecnologias que dão suporte de ajuste de forma integrada e
                garante estabilidade do meio-pé adicional durante os movimentos
                laterais
              </p>
            </S.Feature>
          </S.WrapperItemModelo>

          <S.InfosItemPreco className="itemPrecoMobile">
            <S.TituloInfoPreco>pra quem é esse calçado:</S.TituloInfoPreco>
            <S.TextoInfoPreco>
              O <strong>GEL-RESOLUTION ™ 8</strong> é um tênis ideal para quem
              precisa de mais economia de energia para ter controle total da
              bola. Por ser um modelo responsivo, ele oferece máximo conforto e
              maior resposta do solo.
            </S.TextoInfoPreco>
            <S.Preco>
              R$ <strong>899</strong>,99
            </S.Preco>
            <NavLink
              element="a"
              href="https://www.asics.com.br/calcados?filtro_87=GEL-Resolution-8&utm_source=lab&utm_medium=lp&utm_campaign=crm-cps-tennis&paged=1"
              target="_blank"
              rel="nofollower noreferrer noopener"
            >
              comprar agora
            </NavLink>
          </S.InfosItemPreco>
        </S.ItemModelo>

        <S.ItemModelo className="modelo3">
          <S.WrapperItemModelo>
            <S.TituloModelo>SOLUTION SPEED™ FF 2</S.TituloModelo>
            <S.TextoModelo>
              O modelo mais rápido da linha ASICS para jogadores de tênis,
              combina estabilidade e ajuste flexível garantindo mais velocidade
              para chegar na bola.
            </S.TextoModelo>
            <S.DetalhesModelo>
              <li>
                Para jogo de muita
                <br /> velocidade
              </li>
              <li>Saque-voleio</li>
              <li>
                Amortecimento,
                <br /> flexibilidade e estabilidade
              </li>
              <li>
                Principal tecnologia:
                <br /> TWISSTRUSS™
              </li>
            </S.DetalhesModelo>
          </S.WrapperItemModelo>

          <S.WrapperItemModelo className="detail3">
            <S.ThumbModelo>
              <img src={SolutionSpeedFf} alt="SOLUTION SPEED™ FF 2" />
            </S.ThumbModelo>
          </S.WrapperItemModelo>

          <S.WrapperItemModelo className="detail3">
            <S.Feature>
              <img src={feature1SolutionSpeedFf2} alt="" />
              <p>
                <strong>TWISSTRUSS™</strong>
                <br />
                Melhora o movimento de cortes e transições rápidas, ajudando
                jogadores que estão sempre correndo do fundo da quadra até a
                rede
              </p>
            </S.Feature>
            <S.Feature>
              <img src={feature2SolutionSpeedFf2} alt="" />
              <p>
                <strong>DESIGN DE SOLADO</strong>
                <br />
                Mais tração no calcanhar e na ponta dos dedos, ajudando a criar
                transições mais rápidas
              </p>
            </S.Feature>
            <S.Feature>
              <img src={feature3SolutionSpeedFf2} alt="" />
              <p>
                <strong>DYNAWRAP</strong>
                <br />
                Aplicação de PU no cabedal para mais suporte, flexibilidade e
                ajuste implementado com a tecnologia nos ilhós que estabiliza a
                ponta do pé em movimentos laterais
              </p>
            </S.Feature>
          </S.WrapperItemModelo>

          <S.InfosItemPreco className="itemPrecoMobile">
            <S.TituloInfoPreco>pra quem é esse calçado:</S.TituloInfoPreco>
            <S.TextoInfoPreco>
              O <strong>SOLUTION SPEED™ FF 2</strong> é um tênis para jogadores
              rápidos que querem dar o primeiro passo no ataque. Para um jogo de
              saque-voleio rápido que não dá chance do adversário pensar em um
              contra-ataque.
            </S.TextoInfoPreco>
            <S.Preco>
              R$ <strong>799</strong>,99
            </S.Preco>
            <NavLink
              element="a"
              href="https://www.asics.com.br/calcados?filtro_87=SolutionSpeed-2&utm_source=lab&utm_medium=lp&utm_campaign=crm-cps-tennis&paged=1"
              target="_blank"
              rel="nofollower noreferrer noopener"
            >
              comprar agora
            </NavLink>
          </S.InfosItemPreco>
        </S.ItemModelo>

        <S.Linha />
      </S.Modelos>

      <S.WrapperInfosPreco>
        <S.InfosItemPreco>
          <S.TituloInfoPreco>pra quem é esse calçado:</S.TituloInfoPreco>
          <S.TextoInfoPreco>
            O <strong>COURT FF ™ Novak</strong> é um tênis ideal para jogadores
            que gostam de treinar seu controle de velocidade e movimentação. Se
            você tem um jogo fundo de quadra agressivo inspirado no melhor do
            mundo, esse modelo é para você.
          </S.TextoInfoPreco>
          <S.Preco>
            R$ <strong>1099</strong>,99
          </S.Preco>
          <NavLink
            element="a"
            href="https://www.asics.com.br/calcados?filtro_87=Court-FF-Novak&utm_source=lab&utm_medium=lp&utm_campaign=crm-cps-tennisSolution&paged=1"
            target="_blank"
            rel="nofollower noreferrer noopener"
          >
            comprar agora
          </NavLink>
        </S.InfosItemPreco>

        <S.InfosItemPreco>
          <S.TituloInfoPreco>pra quem é esse calçado:</S.TituloInfoPreco>
          <S.TextoInfoPreco>
            O <strong>GEL-RESOLUTION ™ 8</strong> é um tênis ideal para quem
            precisa de mais economia de energia para ter controle total da bola.
            Por ser um modelo responsivo, ele oferece máximo conforto e maior
            resposta do solo.
          </S.TextoInfoPreco>
          <S.Preco>
            R$ <strong>899</strong>,99
          </S.Preco>
          <NavLink
            element="a"
            href="https://www.asics.com.br/calcados?filtro_87=GEL-Resolution-8&utm_source=lab&utm_medium=lp&utm_campaign=crm-cps-tennis&paged=1"
            target="_blank"
            rel="nofollower noreferrer noopener"
          >
            comprar agora
          </NavLink>
        </S.InfosItemPreco>

        <S.InfosItemPreco>
          <S.TituloInfoPreco>pra quem é esse calçado:</S.TituloInfoPreco>
          <S.TextoInfoPreco>
            O <strong>SOLUTION SPEED™ FF 2</strong> é um tênis para jogadores
            rápidos que querem dar o primeiro passo no ataque. Para um jogo de
            saque-voleio rápido que não dá chance do adversário pensar em um
            contra-ataque.
          </S.TextoInfoPreco>
          <S.Preco>
            R$ <strong>799</strong>,99
          </S.Preco>
          <NavLink
            element="a"
            href="https://www.asics.com.br/calcados?filtro_87=SolutionSpeed-2&utm_source=lab&utm_medium=lp&utm_campaign=crm-cps-tennis&paged=1"
            target="_blank"
            rel="nofollower noreferrer noopener"
          >
            comprar agora
          </NavLink>
        </S.InfosItemPreco>
      </S.WrapperInfosPreco>

      <S.WrapperNewsletter>
        <S.ContentNewsletter>
          <h2>Cadastre seu e-mail para receber ofertas e novidades</h2>

          <S.FormNewsletter>
            <FormNewsletter isLP />
          </S.FormNewsletter>
        </S.ContentNewsletter>
      </S.WrapperNewsletter>
    </Layout>
  )
}

export default CPSTennisPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
