import React, { useContext } from 'react'
import { FaTimes } from '@react-icons/all-files/fa/FaTimes'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { GlobalContext } from '../../Context/global'

import '../../assets/style/modal.scss'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    width: '700px',
  },
}

Modal.setAppElement('#___gatsby')

const ModalDefault = ({ children = null }) => {
  const { modalIsOpen, handleModalClose } = useContext(GlobalContext)

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={handleModalClose}
      style={customStyles}
      contentLabel="Generic modal"
    >
      <button
        className="fechar-modal"
        aria-label="Fechar modal"
        onClick={handleModalClose}
      >
        <FaTimes />
      </button>
      {children}
    </Modal>
  )
}

ModalDefault.propTypes = {
  children: PropTypes.node,
}

export default ModalDefault
