/* Referencia 360: https://github.com/aldrinc/react-360-image */

import React, { Component } from 'react'
import './React360.css'

const pixelsPerDegree = 1

class React360 extends Component {
  static defaultProps = { dir: 'asics-360', numImages: 24, alt: 'asics 360' }

  state = {
    dragging: false,
    imageIndex: 0,
    dragStartIndex: 0,
  }

  componentDidMount = () => {
    document.addEventListener('mousemove', this.handleMouseMove, false)
    document.addEventListener('touchmove', this.handleMouseMove, false)

    document.addEventListener('mouseup', this.handleMouseUp, false)
    document.addEventListener('touchleave', this.handleMouseUp, false)
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousemove', this.handleMouseMove, false)
    document.removeEventListener('touchmove', this.handleMouseMove, false)

    document.removeEventListener('mouseup', this.handleMouseUp, false)
    document.removeEventListener('touchleave', this.handleMouseUp, false)
  }

  handleMouseDown = (e) => {
    e.persist()

    this.setState((state) => ({
      dragging: true,
      dragStart: e.screenX,
      dragStartIndex: state.imageIndex,
    }))
  }

  handleTouchMove = (e) => {
    e.persist()

    this.setState((state) => ({
      dragging: true,
      dragStart: e.touches[0].screenX,
      dragStartIndex: state.imageIndex,
    }))
  }

  handleMouseUp = () => this.setState({ dragging: false })

  updateImageIndex = (currentPosition) => {
    let numImages = this.props.numImages
    const pixelsPerImage = pixelsPerDegree * (360 / numImages)
    const { dragStart, imageIndex, dragStartIndex } = this.state
    let dx = (currentPosition - dragStart) / pixelsPerImage
    let index = Math.floor(dx) % numImages

    if (index < 0) {
      index = numImages + index - 1
    }

    index = (index + dragStartIndex) % numImages

    if (index !== imageIndex) {
      this.setState({ imageIndex: index })
    }
  }

  handleMouseMove = (e) => {
    if (this.state.dragging && e.type === 'touchmove') {
      this.updateImageIndex(e.touches[0].screenX)
      return 
    }

    if (this.state.dragging && e.type === 'mousemove') {
      this.updateImageIndex(e.screenX)
      return
    }
  }

  preventDragHandler = (e) => e.preventDefault()

  renderImage = () => {
    const { imageIndex } = this.state

    return (
      <img
        className="react-360-img"
        alt={this.props.alt}
        src={
          require(`../../assets/images/cps/${this.props.dir}/${imageIndex}.jpg`)
            .default
        }
      />
    )
  }

  render = () => {
    return (
      <div
        className="react-360-img"
        onMouseDown={this.handleMouseDown}
        onDragStart={this.preventDragHandler}
        onTouchStart={this.handleTouchMove}
      >
        {this.renderImage()}
      </div>
    )
  }
}

export default React360
