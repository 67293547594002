export const URL_ESTADOS = (pais) =>
  `${process.env.GATSBY_API_URL}${pais}/foot-id/estado`
export const URL_CIDADES = (pais) =>
  `${process.env.GATSBY_API_URL}${pais}/foot-id/cidade`
export const URL_FILIAIS = (pais) =>
  `${process.env.GATSBY_API_URL}${pais}/foot-id/loja`
export const URL_FILIAIS_QUICK = (pais) =>
  `${process.env.GATSBY_API_URL}${pais}/foot-id/loja?quick=1`
export const URL_AGENDAMENTO = (pais) =>
  `${process.env.GATSBY_API_URL}${pais}/foot-id/agendamento`
export const URL_RESULTADO = (pais) =>
  `${process.env.GATSBY_API_URL}${pais}/foot-id/resultado`

const config = {
  headers: {
    Accept: 'application/json',
  },
}

export function requestOptions(dados) {
  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(dados),
  }
}

export function requestUpload(formData) {
  return {
    headers: {
      Accept: 'application/json',
    },
    method: 'POST',
    body: formData,
  }
}

export async function fetchData(url) {
  let data
  let error

  try {
    const response = await fetch(url, config)
    const json = await response.json()

    if (response.ok) data = json
  } catch (err) {
    error = err
  }

  return { data, error }
}
